import { formatNumber } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AmendmentReasons } from '@app/core/enums/amendment-reason.enum';
import { FeeOwner } from '@app/core/enums/fee-owner.enum';
import { PaymentFrequency } from '@app/core/enums/payment-frequency.enum';
import { PaymentMethod } from '@app/core/enums/payment-method.enum';
import { PlanType } from '@app/core/enums/plan-type.enum';
import { TreatmentType } from '@app/core/enums/treatment-type.enum';
import { InternalIdNamePair } from '@app/core/models/common/form-fields.interface';
import { AmendmentDdrData } from '@app/core/models/payment/amendment';
import { AmendmentPayload } from '@app/core/models/support/amendment-payload.model';
import { AmendmentType } from '@app/core/models/support/amendment-reason';
import { AmendmentService } from '@app/core/services/amendment/amendment.service';
import { PaymentService } from '@app/core/services/payment.service';
import { TierPermissionService } from '@app/core/services/tier-permission/tier-permission.service';
import { AppButton } from '@app/shared/interfaces/button.interface';
import { AppDatePicker } from '@app/shared/interfaces/date-picker.interface';
import { AppDropdown } from '@app/shared/interfaces/dropdown.interface';
import { AppInput, NumberInputConfig } from '@app/shared/interfaces/input.interface';
import { AppRadioGroupConfig } from '@app/shared/interfaces/radio-group.interface';
import { SliderConfig } from '@app/shared/interfaces/slider.interface';
import { CustomValidators } from '@app/shared/validators/non-zero.validator';
import { ModalComponent } from '@core/components/modal/modal.component';
import { AmendmentQuote, UnsubmittedAmendmentData } from '@core/models';
import * as DDRValidator from '@core/services/ddr.validator';
import { HelperService } from '@core/services/helper.service';
import { PlanCalculationService } from '@core/services/plan-calculation.service';
import { environment } from '@environments/environment';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { addDays, format } from 'date-fns';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, finalize, map, startWith, take, tap } from 'rxjs/operators';
import { AmendmentRequestService } from './amendment-request.service';

interface AmendmentRequestData {
  amendmentData: UnsubmittedAmendmentData;
  unsubmittedPlanId?: string;
  readonly: boolean;
  reason?: AmendmentReasons;
}
@Component({
  selector: 'sliqpay-amendment-request',
  templateUrl: './amendment-request.component.html',
  styleUrls: ['./amendment-request.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AmendmentRequestService]
})
export class AmendmentRequestComponent implements OnInit, OnDestroy {
  @Input() data!: AmendmentRequestData;

  LANG_CONFIG_KEY = 'support_amendment_component';
  MAX_START_DATE_IN_DAYS = 180;
  MAX_TERM_IN_MONTHS = this.helperService.getObjUser()?.segment.custrecord_maximum_term;

  supportAmendmentForm!: UntypedFormGroup;
  isViewAmendment = false;
  ddrData: AmendmentDdrData;
  selectedReason?: AmendmentType;
  procedures!: InternalIdNamePair[];
  maxRepaymentAmountForFrequency = 0;
  remainingDurationDisplayText = '';
  maxAmendmentPlanAmount = 0;

  reasons$ = new BehaviorSubject<AmendmentType[]>([]);
  quote$ = new BehaviorSubject<AmendmentQuote>({
    term_weeks: 0,
    term_months: 0,
    weekly: 0,
    fortnightly: 0,
    monthly: 0
  });
  selectedReasonSubject = new BehaviorSubject<AmendmentReasons>(AmendmentReasons.NewTreatment);

  planTypes = PlanType;
  amendmentReasons = AmendmentReasons;
  loading$ = new BehaviorSubject(false);
  placeholderAmendmentQuote: AmendmentQuote = {
    term_weeks: 0,
    term_months: 0,
    weekly: 0,
    fortnightly: 0,
    monthly: 0
  };

  private subs = new Subscription();

  // Form fields and configs
  frequencies: InternalIdNamePair[] = [];
  reasonDdConfig$!: Observable<AppDropdown>;
  proceduresDdConfig$!: Observable<AppDropdown>;
  getRemainingPlanAmountDisabled$!: Observable<AppInput>;
  planAmountChangeConfig$!: Observable<NumberInputConfig>;
  newPlanAmountConfig$!: Observable<AppInput>;
  requestorNameConfig$!: Observable<AppInput>;
  requestorEmailConfig$!: Observable<AppInput>;
  planTermConfig$!: Observable<{ slider: SliderConfig; input: NumberInputConfig }>;
  feeOwnerConfig$!: Observable<AppDropdown>;
  proceedBtnConfig$!: Observable<AppButton>;
  submitCancellationRequestBtnConfig$!: Observable<AppButton>;
  updateAmendmentBtnConfig$!: Observable<AppButton>;
  startDateDatePickerConfig$!: Observable<AppDatePicker>;
  paymentFrequencyConfig$!: Observable<AppRadioGroupConfig>;

  paymentFrequencyOptions$: Observable<any[]> = of([]);

  get quoteGroup(): UntypedFormArray {
    return this.supportAmendmentForm.get('quote') as UntypedFormArray;
  }

  get initialPlanAmount(): AbstractControl | null {
    return this.supportAmendmentForm.get('initialPlanAmount');
  }

  get planAmountChange(): AbstractControl | null {
    return this.supportAmendmentForm.get('planAmountChange');
  }

  get newPlanAmount(): AbstractControl | null {
    return this.supportAmendmentForm.get('total_plan_value');
  }

  get orthoItems(): AbstractControl | null {
    return this.supportAmendmentForm.get('orthoItems');
  }

  get planDurationInMonths(): AbstractControl | null {
    return this.supportAmendmentForm.get('newDuration');
  }

  get frequency(): AbstractControl | null {
    return this.supportAmendmentForm.get('payment_freq');
  }

  get directDebitAmount(): AbstractControl | null {
    return this.supportAmendmentForm.get('newDirectDebitAmount');
  }

  get debitCount(): AbstractControl | null {
    return this.supportAmendmentForm.get('no_of_payments');
  }

  get requestorName(): AbstractControl | null {
    return this.supportAmendmentForm.get('requestorName');
  }

  get requestorEmail(): AbstractControl | null {
    return this.supportAmendmentForm.get('requestorEmail');
  }

  get amendmentStartDate(): AbstractControl | null {
    return this.supportAmendmentForm.get('start_date');
  }

  get amendmentFeeOwner(): AbstractControl | null {
    return this.supportAmendmentForm.get('amendmentFeeOwner');
  }

  get reason(): AbstractControl | null {
    return this.supportAmendmentForm.get('reason');
  }

  get showFeeOwnerSelection(): boolean {
    return [AmendmentReasons.NewTreatment, AmendmentReasons.FrequencyChange].includes(this.selectedReason?.id as AmendmentReasons);
  }

  get isFrequencyOptionsDisabled(): boolean {
    const quote = this.quote$.getValue();
    return this.isViewAmendment || this.newPlanAmount?.value <= 0 || quote.weekly <= 0;
  }

  get isAmendmentDecrease(): boolean {
    return this.amendmentService.getDecreaseAmendmentTypes().includes(this.reason?.value);
  }

  get isOrthoTreatmentType(): boolean {
    return this.ddrData.custrecord_pa_custfield_ddr_treatment === TreatmentType.ORTHO;
  }

  constructor(
    public helperService: HelperService,
    public planCalculationService: PlanCalculationService,
    public activeModal: NgbActiveModal,
    private modal: NgbModal,
    private saService: AmendmentRequestService,
    private fb: UntypedFormBuilder,
    private amendmentService: AmendmentService,
    private tierPermissionService: TierPermissionService,
    private paymentService: PaymentService
  ) {
    this.ddrData = {
      amendmentReasonList: [],
      amendmentFeeOwnerList: [],
      amendmentFeeOwner: 0,
      custrecord_mfa_ddr_single_payments: '0',
      custrecord_mfa_ddr_payment_frequency: [{ text: '', value: '' }],
      custrecord_mfa_responsible_party: '',
      custrecord_rp_first_name: '',
      custrecord_mfa_rp_record: [{ text: '', value: '' }],
      custrecord_pa_custfield_ddr_treatment: '',
      custrecord_title: [{ text: '', value: '' }],
      custrecord_practice_patient_id_no: '',
      custrecord_custentity_providercontactdr: [{ text: '', value: '' }],
      custrecord_mfa_ddr_plan_type: [{ text: '', value: '' }],
      custrecord_dr_tr_fee_owner: [{ text: '', value: '' }],
      'custrecord_default_rp_pymt_mtd.custrecord_payment_method_method': [{ text: '', value: '' }],
      custrecord_mfa_amount: '0',
      custrecord_mfa_residual: 0,
      rpLastName: '',
      rpMobileNumber: '',
      ddrId: '',
      rpID: '',
      recurringStartDate: '',
      numOfPayment: 0,
      custrecord_mfa_patient: '',
      termLength: 0,
      'custrecord_mfa_ddr_plan_type.custrecord_ddrtype_adjustment_fee_tax': '',
      'custrecord_mfa_ddr_plan_type.custrecord_ddrtype_amendment_fee_tax': ''
    };
  }

  ngOnInit(): void {
    this.setFormFields();
    this.initializeForm();

    this.initPlanCalculator();
    this.initQuoteCalculator();

    this.procedures = this.saService.getProcedureItems();
    this.isViewAmendment = this.data.readonly || false;
    this.maxAmendmentPlanAmount = this.planCalculationService.maxAmendmentIncreasePlan;

    this.getDdrData();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onAmendmentReasonChange(value: number): void {
    this.selectedReason = this.reasons$.getValue().find((r) => r.id === value);

    if (this.selectedReason) {
      this.selectedReasonSubject.next(this.selectedReason.id);
      this.resetDefaultValuesByReason(this.selectedReason.id);
      this.updateStateByReason(this.selectedReason.id);
      this.updateValidatorsByReason(this.selectedReason.id);
    }
  }

  openAcknowledgementDialog(): void {
    if (!this.supportAmendmentForm.valid) {
      this.supportAmendmentForm.markAllAsTouched();
      return;
    }

    const chargeConfirmationModal = this.modal.open(ModalComponent, {
      centered: true
    });
    chargeConfirmationModal.componentInstance.data = {
      title: 'Acknowledgement',
      content: this.getAcknowledgementMessage(),
      buttons: [
        {
          text: 'Proceed',
          class: 'btn-primary w-230',
          value: true
        }
      ]
    };
    chargeConfirmationModal.closed.subscribe((saveAmendment) => {
      if (saveAmendment) {
        this.submitAmendmentRequest();
      }
    });
  }

  submitCancellationRequest(): void {
    if (!this.supportAmendmentForm.valid) {
      this.supportAmendmentForm.markAllAsTouched();
      return;
    }

    this.loading$.next(true);

    // log consolidated plan when env is not prod
    if (!environment.production) {
      console.log({
        ...this.getAmendmentSummaryPayload()
      });
    }

    this.amendmentService
      .createAmendmentElement(this.getAmendmentSummaryPayload())
      .pipe(
        take(1),
        finalize(() => this.loading$.next(false))
      )
      .subscribe((response) => {
        if (this.helperService.checkAPIResponse(response)) {
          this.activeModal.close();
          this.openThankYouModal({
            title: 'Thank you',
            message: response.data[0].msg[0].message
          });
        }
      });
  }

  updateAmendment(): void {
    if (!this.supportAmendmentForm.valid || !this.data.unsubmittedPlanId) {
      this.supportAmendmentForm.markAllAsTouched();
      return;
    }

    this.loading$.next(true);

    // log consolidated plan when env is not prod
    if (!environment.production) {
      console.log({
        ...this.getAmendmentSummaryPayload()
      });
    }

    this.amendmentService
      .updateAmendmentRequest(this.data.unsubmittedPlanId, this.getAmendmentSummaryPayload())
      .pipe(take(1))
      .subscribe(
        (response) => {
          this.loading$.next(false);

          if (this.helperService.checkAPIResponse(response)) {
            const unsubmittedPlan = this.data.unsubmittedPlanId
              ? this.paymentService.getUnsubmittedPlanById(this.data.unsubmittedPlanId)
              : null;

            if (this.data.unsubmittedPlanId && unsubmittedPlan) {
              unsubmittedPlan.json_transformed = {
                ...unsubmittedPlan.json_transformed,
                ...this.getAmendmentSummaryPayload()
              };
              const transformedModel = this.paymentService.transformToApiModel(unsubmittedPlan);
              this.paymentService.updateUnsubmittedPlan(this.data.unsubmittedPlanId, transformedModel);
            }

            this.activeModal.close();
            this.openThankYouModal({
              title: 'Thank you',
              message: 'Amendment updated successfully.'
            });
          }
        },
        () => this.loading$.next(false)
      );
  }

  formatAmendmentAmountToCurrency(value: any): void {
    this.planAmountChange?.setValue(this.helperService.formatMoney(value || 0));
  }

  private calculateQuotes(): void {
    const quoteSnapshot = this.quote$.getValue();
    this.directDebitAmount?.setValue(this.getDebitAmountByFrequency(this.frequency?.value));

    const updatedAmendmentQuotes: AmendmentQuote[] = this.planCalculationService.generateAmendmentQuotes(
      +this.newPlanAmount?.value,
      +this.directDebitAmount?.value,
      [quoteSnapshot]
    );

    const debitCount = Math.round(
      this.planCalculationService.calculateNumberOfDebits(
        this.frequency?.value,
        this.planDurationInMonths?.value,
        this.newPlanAmount?.value,
        this.directDebitAmount?.value
      )
    ).toString();

    this.debitCount?.setValue(debitCount);
    this.quote$.next(updatedAmendmentQuotes[0]);
    this.maxRepaymentAmountForFrequency = this.saService.getMaxRepaymentAmountForFrequency(this.frequency?.value);
  }

  private calculateNewPlanAmount(): void {
    const current = this.helperService.formatMoney(this.ddrData.custrecord_mfa_residual) || '0';
    const change = this.planAmountChange?.value || 0;

    let _newPlanAmount = 0;
    if (this.reason?.value === AmendmentReasons.NewTreatment) {
      _newPlanAmount = parseFloat(current) + parseFloat(change);
    } else if (this.reason?.value === AmendmentReasons.FrequencyChange) {
      _newPlanAmount = parseFloat(current);
    } else {
      _newPlanAmount = parseFloat(current) - parseFloat(change);
    }
    this.newPlanAmount?.setValue(_newPlanAmount.toFixed(2));
  }

  private setRemainingDurationDisplayText(selectedFrequency: PaymentFrequency): void {
    const displayTextMap = new Map<PaymentFrequency, any>([
      [
        PaymentFrequency.Weekly,
        {
          singular: 'week',
          plural: 'weeks'
        }
      ],
      [
        PaymentFrequency.Fortnightly,
        {
          singular: 'fortnight',
          plural: 'fortnights'
        }
      ],
      [
        PaymentFrequency.Monthly,
        {
          singular: 'month',
          plural: 'months'
        }
      ]
    ]);

    if (displayTextMap.get(selectedFrequency)) {
      this.remainingDurationDisplayText =
        this.ddrData.numOfPayment === 1 ? displayTextMap.get(selectedFrequency).singular : displayTextMap.get(selectedFrequency).plural;
    }
  }

  private resetDefaultValuesByReason(reason: AmendmentReasons): void {
    this.planAmountChange?.setValue(this.helperService.formatMoney(0));
    this.amendmentStartDate?.setValue(new Date(this.ddrData.recurringStartDate));
    this.orthoItems?.setValue([]);

    // to update validation
    this.amendmentStartDate?.updateValueAndValidity();
  }

  // field state: enabled/disabled
  private updateStateByReason(reason: AmendmentReasons): void {
    this.amendmentStartDate?.disable();

    if (reason === AmendmentReasons.FrequencyChange) {
      this.amendmentStartDate?.enable();
    }

    this.amendmentStartDate?.updateValueAndValidity();
  }

  private updateValidatorsByReason(reason: AmendmentReasons): void {
    this.planAmountChange?.setValidators([Validators.required, CustomValidators.NonZero]);

    if (reason === AmendmentReasons.FrequencyChange) {
      this.planAmountChange?.clearValidators();
    }

    this.planAmountChange?.updateValueAndValidity();
  }

  private initPlanCalculator(): void {
    this.subs.add(
      combineLatest([
        this.planAmountChange?.valueChanges.pipe(
          startWith(0),
          distinctUntilChanged(),
          map((val) => (val !== null ? val : 0))
        ),
        this.reason?.valueChanges.pipe(startWith(null))
      ])
        .pipe(distinctUntilChanged())
        .subscribe((planAmount) => {
          this.calculateNewPlanAmount();

          if (+this.newPlanAmount?.value <= 0) {
            this.quote$.next(this.placeholderAmendmentQuote);

            this.planDurationInMonths?.reset({ value: 0, disabled: true });
            this.frequency?.reset();

            this.planDurationInMonths?.removeValidators(Validators.required);
            this.frequency?.removeValidators(Validators.required);
          } else {
            this.planDurationInMonths?.enable();

            this.frequency?.addValidators(Validators.required);
            this.planDurationInMonths?.addValidators(Validators.required);
          }

          this.frequency?.updateValueAndValidity();
          this.planDurationInMonths?.updateValueAndValidity();
        })
    );
  }

  private initQuoteCalculator(): void {
    if (!(this.planDurationInMonths && this.newPlanAmount && this.reason && this.frequency)) {
      return;
    }

    this.subs.add(
      combineLatest([
        this.planDurationInMonths.valueChanges.pipe(
          startWith(0),
          tap((value) => {
            if (value == null) {
              this.planDurationInMonths?.setValue(0);
            }
          })
        ),
        this.newPlanAmount.valueChanges.pipe(
          startWith(this.helperService.formatMoney(0)),
          map((value) => +value)
        ),
        this.reason?.valueChanges.pipe(startWith(null)),
        this.frequency?.valueChanges.pipe(startWith(null))
      ])
        .pipe(distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)))
        .subscribe(([duration, amount, reason]) => {
          if (duration <= 0 || duration === null) {
            this.frequency?.patchValue(null);
            this.quote$.next(this.placeholderAmendmentQuote);
            return;
          }

          if (duration > this.MAX_TERM_IN_MONTHS) {
            this.planDurationInMonths?.setValue(this.MAX_TERM_IN_MONTHS);
            return;
          }

          if (amount >= 0) {
            // attach duration
            const newQuotes = { ...this.quote$.getValue(), term_months: +duration };
            this.quote$.next(newQuotes);

            this.calculateQuotes();
          }
        })
    );
  }

  private getDdrData(): void {
    this.amendmentService
      .getCurrentPlan$()
      .pipe(take(1))
      .subscribe((ddrData: AmendmentDdrData | null) => {
        if (ddrData === null) {
          return;
        }

        this.ddrData = ddrData;
        this.reasons$.next(this.initAmendmentReasonList(ddrData));

        if (this.data.amendmentData) {
          this.populateFields();
          this.updateStateByReason(this.reason?.value);
          this.supportAmendmentForm.markAllAsTouched();
        } else {
          const selectedReason =
            this.tierPermissionService.getAmendmentIncreasePermission() === false
              ? AmendmentReasons.CancelTreatment
              : AmendmentReasons.NewTreatment;
          this.supportAmendmentForm.patchValue({
            reason: this.data.reason || selectedReason,
            planAmountChange: this.helperService.formatMoney(0),
            remainingPlanAmount: this.helperService.formatMoney(this.ddrData.custrecord_mfa_residual),
            total_plan_value: this.helperService.formatMoney(this.ddrData.custrecord_mfa_residual),
            requestorEmail: this.helperService.getObjUser()?.email,
            start_date: new Date(this.ddrData.recurringStartDate),
            amendmentFeeOwner: this.ddrData.amendmentFeeOwner,
            newDuration: this.ddrData.termLength,
            payment_freq: this.ddrData.custrecord_mfa_ddr_payment_frequency[0]?.value,
            no_of_payments: this.ddrData.numOfPayment
          });
          this.onAmendmentReasonChange(this.reason?.value);
        }

        if (this.isViewAmendment) {
          this.supportAmendmentForm.disable();
        }

        this.setRemainingDurationDisplayText(this.ddrData.custrecord_mfa_ddr_payment_frequency[0].value as PaymentFrequency);
      });
  }

  private submitAmendmentRequest(): void {
    if (!this.supportAmendmentForm.valid) {
      return;
    }

    this.loading$.next(true);

    // log consolidated plan when env is not prod
    if (!environment.production) {
      console.log({
        ...this.getAmendmentSummaryPayload()
      });
    }

    this.amendmentService
      .createAmendmentAdjustmentRequest(this.getAmendmentSummaryPayload())
      .pipe(take(1))
      .subscribe(
        (response) => {
          this.loading$.next(false);

          if (this.helperService.checkAPIResponse(response)) {
            this.activeModal.close();
            this.openThankYouModal({
              title: 'Thank you',
              message: response.data[0].msg[0].message
            });
          }
        },
        () => this.loading$.next(false)
      );
  }

  private initAmendmentReasonList(ddrData: AmendmentDdrData): AmendmentType[] {
    return ddrData.amendmentReasonList.map((reason) => {
      if (reason.id == AmendmentReasons.NewTreatment && this.tierPermissionService.getAmendmentIncreasePermission() === false) {
        reason.disabled = true;
      }
      return {
        ...reason,
        fee: this.getAmendmentFee(reason.id, ddrData),
        symbol: reason.id === AmendmentReasons.NewTreatment ? '+' : '-'
      };
    });
  }

  private initializeForm(): void {
    this.supportAmendmentForm = this.fb.group(
      {
        reason: [null, Validators.required],
        remainingPlanAmount: [this.helperService.formatMoney(0)],
        planAmountChange: [this.helperService.formatMoney(0), [Validators.required]],
        total_plan_value: [
          this.helperService.formatMoney(0),
          [Validators.required, Validators.max(this.planCalculationService.maxAmendmentIncreasePlan)]
        ],
        newDuration: [
          0,
          {
            validators: [
              Validators.required,
              Validators.min(this.planCalculationService.computeMinTermMonths),
              Validators.max(this.MAX_TERM_IN_MONTHS)
            ]
          }
        ],
        payment_freq: [null, Validators.required],
        start_date: [null, [DDRValidator.minDateValidator(new Date()), Validators.required]],
        requestorName: ['', Validators.required],
        requestorEmail: ['', DDRValidator.rp_email_validator],
        no_of_payments: [0, Validators.required],
        newDirectDebitAmount: [0],
        amendmentFeeOwner: [null, Validators.required],
        orthoItems: [[]]
      },
      {
        validator: [this.repaymentAmountForFrequencyValidator(), this.minPaymentPlanAmountValidator(), this.maxStartDateValidtor()]
      }
    );
  }

  private populateFields(): void {
    const amendmentData = this.data.amendmentData;

    const [day, month, year] = amendmentData.start_date.split('/');
    const planAmountChange = amendmentData.additionalPlanAmount ? Math.abs(+amendmentData.additionalPlanAmount) : 0;

    this.supportAmendmentForm.patchValue({
      reason: amendmentData.amendmentReason,
      remainingPlanAmount: this.helperService.formatMoney(this.ddrData.custrecord_mfa_residual),
      planAmountChange: this.helperService.formatMoney(planAmountChange),
      newPlanAmount: amendmentData.total_plan_value,
      newDuration: amendmentData.quote[0].term_months,
      payment_freq: amendmentData.payment_freq,
      amendmentFeeOwner: +amendmentData.amendmentFeeOwner,
      requestorName: amendmentData.custrecord_app_requestor,
      requestorEmail: amendmentData.custrecord_app_requestor_email,
      start_date: new Date(+year, +month - 1, +day),
      orthoItems: amendmentData.ortho_items
    });
    this.quote$.next(amendmentData.quote[0]);

    this.selectedReason = this.reasons$.getValue().find((r) => r.id === this.reason?.value);
    this.selectedReasonSubject.next(this.reason?.value);
  }

  private repaymentAmountForFrequencyValidator(): ValidatorFn {
    return (formGroup: AbstractControl) => {
      const planType = this.amendmentService.plan.getValue()?.custrecord_mfa_ddr_plan_type[0].value;
      if (planType === PlanType.NonGuaranteed) {
        return null;
      }

      const frequencyId = formGroup.get('payment_freq')?.value;
      const freqName = this.helperService.getPaymentFrequencyName(frequencyId).toLowerCase();
      const quoteSnapshot = this.quote$.getValue();
      const value = quoteSnapshot[freqName as keyof AmendmentQuote];

      if (this.saService.isMaxRepaymentReachedForFrequency(value, frequencyId)) {
        return { maxFrequencyRepaymentAmount: true };
      }

      return null;
    };
  }

  private minPaymentPlanAmountValidator(): ValidatorFn {
    return (formGroup: AbstractControl) => {
      const amendmentReason = formGroup.get('reason')?.value;
      const newPlanAmount = formGroup.get('total_plan_value')?.value;
      const minPlanAmount = this.getMinPlanAmountByReason(amendmentReason);

      if (newPlanAmount < minPlanAmount) {
        return { minPlanAmount: true };
      }

      return null;
    };
  }

  private maxStartDateValidtor(): ValidatorFn {
    return (form: AbstractControl) => {
      const selectedDate = form.get('start_date')?.value;
      const selectedReason = form.get('reason')?.value;
      const maxDateByReason = this.getMaxStartDateByReason(selectedReason);

      if (selectedDate > maxDateByReason) {
        return { valueGreaterThanMaxDate: true };
      }
      return null;
    };
  }

  private getMinPlanAmountByReason(reason: AmendmentReasons): number {
    switch (reason) {
      case AmendmentReasons.HealthFundPayment:
      case AmendmentReasons.ManualPayment:
      case AmendmentReasons.CancelTreatment: {
        return this.helperService.toFixedNumber(this.ddrData.custrecord_mfa_residual - +this.ddrData.custrecord_mfa_amount, 2);
      }
      case AmendmentReasons.NewTreatment:
      case AmendmentReasons.FrequencyChange: {
        return this.planCalculationService.minAdditionalPlanAmount;
      }
      default:
        return 0;
    }
  }

  private getMaxStartDateByReason(reason: AmendmentReasons): Date {
    if (reason !== AmendmentReasons.FrequencyChange) {
      return this.ddrData.recurringStartDate ? new Date(this.ddrData.recurringStartDate) : new Date();
    }

    return addDays(new Date(), this.MAX_START_DATE_IN_DAYS);
  }

  private getAmendmentSummaryPayload(): AmendmentPayload {
    const {
      custrecord_practice_patient_id_no,
      custrecord_title,
      custrecord_mfa_patient,
      custrecord_rp_first_name,
      rpLastName,
      rpID,
      ddrId,
      rpMobileNumber,
      custrecord_mfa_ddr_plan_type,
      custrecord_mfa_residual
    } = this.ddrData;

    const defaultPayload = {
      rpLastName,
      ddrId,
      custrecord_practice_patient_id_no,
      rpId: rpID,
      rpFirstName: custrecord_rp_first_name,
      recepientMobileNo: rpMobileNumber,
      patientFullName: custrecord_mfa_patient,
      custrecord_title: custrecord_title[0] ? custrecord_title[0].value : '',
      plan_type: custrecord_mfa_ddr_plan_type[0] ? custrecord_mfa_ddr_plan_type[0].value : '',
      // static values
      custrecord_mfa_ddr_term_length: '',
      company_brand: environment.company_brand,
      deposit: this.helperService.formatMoney(0)
    };

    const planAmountChange = this.amendmentService.getDecreaseAmendmentTypes().includes(this.reason?.value)
      ? `-${this.helperService.formatMoney(this.planAmountChange?.value)}`
      : `${this.helperService.formatMoney(this.planAmountChange?.value)}`;

    return {
      ...defaultPayload,
      custrecord_mfa_residual: this.helperService.formatMoney(custrecord_mfa_residual),
      additionalPlanAmount: planAmountChange,
      total_plan_value: this.newPlanAmount?.value,
      payment_plan_total: this.newPlanAmount?.value,
      payment_freq: this.frequency?.value,
      no_of_payments: this.debitCount?.value,
      start_date: format(this.amendmentStartDate?.value, 'MM/dd/yyyy'),
      custrecord_app_requestor: this.requestorName?.value,
      custrecord_app_requestor_email: this.requestorEmail?.value,
      custrecord_mfa_ddr_single_payments: this.getDebitAmountByFrequency(this.frequency?.value),
      quote: [this.quote$.getValue(), this.placeholderAmendmentQuote, this.placeholderAmendmentQuote],
      amendmentFeeOwner: this.amendmentFeeOwner?.value,
      amendmentReason: this.reason?.value,
      custrecord_mfa_provider_element_reason: this.reason?.value,
      ortho_items: this.orthoItems?.value
    };
  }

  private getDebitAmountByFrequency(frequency: PaymentFrequency): number {
    const quoteSnapshot = this.quote$.getValue();
    const quoteMap = new Map<PaymentFrequency, number>([
      [PaymentFrequency.Weekly, quoteSnapshot.weekly],
      [PaymentFrequency.Fortnightly, quoteSnapshot.fortnightly],
      [PaymentFrequency.Monthly, quoteSnapshot.monthly]
    ]);

    return quoteMap.get(frequency) || 0;
  }

  private openThankYouModal(data: { message: string; title: string }): void {
    const successModal = this.modal.open(ModalComponent, {
      centered: true,
      size: 'sm'
    });
    successModal.componentInstance.data = {
      title: data.title,
      content: data.message,
      iconImage: 'icon-green-check',
      buttons: [
        {
          text: 'Close',
          class: 'btn-primary w-230',
          value: false
        }
      ]
    };
    successModal.closed.subscribe();
  }

  private getAmendmentFee(reasonId: number, ddrData: AmendmentDdrData): number {
    switch (reasonId) {
      case AmendmentReasons.NewTreatment:
        return +ddrData['custrecord_mfa_ddr_plan_type.custrecord_ddrtype_amendment_fee_tax'];
      case AmendmentReasons.FrequencyChange:
        return +ddrData['custrecord_mfa_ddr_plan_type.custrecord_ddrtype_adjustment_fee_tax'];
      default:
        return 0;
    }
  }

  private getAcknowledgementMessage(): string {
    const isNumberOfPaymentsIncreased = +this.ddrData.numOfPayment < +this.debitCount?.value;
    const providerOwnsBankTxnFees = this.getBankTxnFeeOwner() === FeeOwner.Provider;
    const isPaymentMethodBankAcct = this.getPaymentMethod() === PaymentMethod.BankAccount;

    return isNumberOfPaymentsIncreased && providerOwnsBankTxnFees && isPaymentMethodBankAcct
      ? 'This amendment request will change the practice payment schedule and next payment date and may incur additional transaction fees. The updated payment schedule will be available within your portal and emailed to you once processed.'
      : 'This amendment request will change the practice payment schedule and next payment date. The updated payment schedule will be available within your portal and emailed to you once processed.';
  }

  private getBankTxnFeeOwner(): string {
    return !!this.ddrData.custrecord_dr_tr_fee_owner && this.ddrData.custrecord_dr_tr_fee_owner.length > 0
      ? this.ddrData.custrecord_dr_tr_fee_owner[0].value
      : '';
  }

  private getPaymentMethod(): string {
    const paymentMethodKey = 'custrecord_default_rp_pymt_mtd.custrecord_payment_method_method';
    return !!this.ddrData[paymentMethodKey] && this.ddrData[paymentMethodKey].length > 0 ? this.ddrData[paymentMethodKey][0].value : '';
  }

  private setFormFields(): void {
    this.planAmountChangeConfig$ = this.saService.getPlanAmountChangeConfig$();
    this.proceduresDdConfig$ = this.saService.getOrthoItemsDdConfig$();
    this.frequencies = this.saService.getFrequencies();
    this.getRemainingPlanAmountDisabled$ = this.saService.getRemainingPlanAmountInputConfig$();
    this.reasonDdConfig$ = this.saService.getAmendmentReasonConfig$();
    this.newPlanAmountConfig$ = this.saService.getNewPlanTotalAmountConfig$();
    this.requestorNameConfig$ = this.saService.getRequestorNameConfig$();
    this.requestorEmailConfig$ = this.saService.getRequestorEmailConfig$();
    this.feeOwnerConfig$ = this.saService.getFeeOwnerConfig$();
    this.proceedBtnConfig$ = this.saService.getProceedBtnConfig$();
    this.submitCancellationRequestBtnConfig$ = this.saService.getSubmitCancellationRequestBtnConfig$();
    this.updateAmendmentBtnConfig$ = this.saService.getUpdateAmendmentBtnConfig$();
    this.paymentFrequencyConfig$ = this.saService.getPaymentFrequencyRadioGroupConfig$();
    this.startDateDatePickerConfig$ = combineLatest([
      this.saService.getStartDateDatePickerConfig$(),
      this.selectedReasonSubject.asObservable()
    ]).pipe(
      map(([dpConfig, reason]) => ({
        ...dpConfig,
        minDate: new Date(),
        maxDate: this.getMaxStartDateByReason(reason)
      }))
    );
    this.planTermConfig$ = combineLatest([
      this.selectedReasonSubject.asObservable(),
      this.saService.getDurationInputConfig$(),
      this.saService.getDurationSliderConfig$()
    ]).pipe(
      map(([reason, input, slider]) => {
        return {
          slider: {
            ...slider,
            min: 0,
            max: this.MAX_TERM_IN_MONTHS,
            label: reason !== AmendmentReasons.FrequencyChange ? '' : slider.label
          },
          input: {
            ...input,
            label: reason === AmendmentReasons.FrequencyChange ? '' : input.label
          }
        };
      })
    );
    this.paymentFrequencyOptions$ = this.quote$.pipe(
      map((quote) => {
        const paymentFrequencies = this.saService.getFrequencies();
        return paymentFrequencies.reduce<any>((_options, frequency) => {
          const frequencyValue = this.getDebitAmountByInternalId(quote, frequency.internalid as PaymentFrequency);
          const option = {
            internalid: frequency.internalid,
            displayText: `$${formatNumber(frequencyValue, 'en-AU', '1.2-2')} ${frequency.name}`
          };
          return [..._options, option];
        }, []);
      })
    );
  }

  private getDebitAmountByInternalId(quote: AmendmentQuote, paymentFrequency: PaymentFrequency): number {
    switch (paymentFrequency) {
      case PaymentFrequency.Weekly:
        return quote.weekly;
      case PaymentFrequency.Fortnightly:
        return quote.fortnightly;
      case PaymentFrequency.Monthly:
        return quote.monthly;
    }
  }
}
