<ng-container *ngIf="config">
  <ng-container *ngIf="isFormGroup; else simpleSelect">
    <div class="form-group" [formGroup]="form">
      <label
        class="text-truncate w-100"
        [ngClass]="{
          disabled: readonly || disabled,
          'required-field': isRequired && config.showRequiredMarker && !disabled
        }"
        *ngIf="config.label"
        >{{ config.label }}</label
      >
      <ng-select
        formControlName="dropdown"
        class="pp-dropdown"
        [appendTo]="config.appendToBody ? 'body' : ''"
        [ngClass]="{
          'pp-dropdown-left': config.textAlign === 'left',
          'pp-dropdown-center': config.textAlign === 'center',
          'pp-dropdown-error': !disabled && hasError && (form.get('dropdown')?.touched || control.touched)
        }"
        [class.longer-dd]="config.longerDropdown"
        [bindLabel]="bindLabel"
        [bindValue]="bindValue"
        [clearable]="clearable"
        [placeholder]="placeholder"
        [readonly]="readonly || disabled"
        [multiple]="multiple"
        [searchable]="searchable"
        [searchFn]="searchFn"
        (change)="onDropdownChange($event)"
        (clear)="onClear()"
        (search)="onSearch($event)"
        (blur)="onDropdownTouched()"
      >
        <ng-option *ngFor="let item of items" [value]="item" [disabled]="item?.disabled">
          <ng-container *ngIf="bindLabel; else showNoBinding">
            {{ item[bindLabel] }}
          </ng-container>
          <ng-template #showNoBinding>
            {{ item }}
          </ng-template>
        </ng-option>
      </ng-select>

      <div class="invalid-feedback d-block" *ngIf="hasError && (form.get('dropdown')?.touched || control.touched)">
        <div *ngIf="hasError?.required || hasError?.invalid">
          {{ config.errors?.required }}
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #simpleSelect>
    <div [class.isSideLabel]="isSideLabel">
      <label
        class="text-truncate w-100"
        [ngClass]="{
          disabled: readonly || disabled,
          'required-field': isRequired && config.showRequiredMarker && !disabled
        }"
        *ngIf="config.label"
        >{{ config.label }}</label
      >
      <ng-select
        class="pp-dropdown"
        [ngClass]="{
          'pp-dropdown-left': config.textAlign === 'left',
          'pp-dropdown-center': config.textAlign === 'center',
          'pp-dropdown-error': !disabled && hasError && (form.get('dropdown')?.touched || control.touched)
        }"
        [appendTo]="config.appendToBody ? 'body' : ''"
        [(ngModel)]="selectedValue"
        [bindLabel]="bindLabel"
        [bindValue]="bindValue"
        [clearable]="clearable"
        [placeholder]="placeholder"
        [readonly]="readonly || disabled"
        [multiple]="multiple"
        [searchable]="searchable"
        [searchFn]="searchFn"
        (change)="onSimpleDdChange($event)"
        (clear)="onClear()"
        (search)="onSearch($event)"
      >
        <ng-option *ngFor="let item of items" [value]="item" [disabled]="item?.disabled">
          <ng-container *ngIf="bindLabel; else showNoBinding">
            {{ item[bindLabel] }}
          </ng-container>
          <ng-template #showNoBinding>
            {{ item }}
          </ng-template>
        </ng-option>
      </ng-select>
    </div>
  </ng-template>
</ng-container>
