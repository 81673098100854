import { Injectable } from '@angular/core';
import { ProviderType } from '@app/core/enums/provider-type.enum';
import { TextValuePair } from '@app/core/models/common/form-fields.interface';
import {
  BnplSettings,
  ConnectServices,
  ConnectSettings,
  GeneralSettings,
  PlusSettings,
  UserSettings
} from '../../models/payment/user-settings.interface';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {
  constructor() {}

  getSettings(): UserSettings {
    return JSON.parse(sessionStorage.getItem('user') as string)?.settings;
  }

  getSegment(): any {
    return JSON.parse(sessionStorage.getItem('user') as string)?.segment;
  }

  getBnplSettings(): BnplSettings {
    return this.getSettings()?.bnplSettings;
  }

  getConnectServices(): ConnectServices {
    return this.getSettings()?.connectServices;
  }

  getConnectSettings(): ConnectSettings {
    return this.getSettings()?.connectSettings;
  }

  getPlusSettings(): PlusSettings {
    return this.getSettings()?.plusSettings;
  }

  getGeneralSettings(): GeneralSettings {
    return this.getSettings()?.generalSettings;
  }

  isBnplEnabled(): boolean {
    return this.getBnplSettings()?.enablePlanType;
  }

  isPlusEnabled(): boolean {
    return this.getPlusSettings()?.enablePlanType;
  }

  isConnectEnabled(): boolean {
    return this.getConnectSettings()?.enablePlanType;
  }

  isProactiveConnectEnabled(): boolean {
    return this.getConnectSettings()?.enableProactiveConnect;
  }

  isProactiveConnectOnlyProvider(): boolean {
    return !this.isPlusEnabled() && this.isProactiveConnectEnabled();
  }

  isPlusOnlyProvider(): boolean {
    return !this.isBnplEnabled() && this.isPlusEnabled() && !this.isConnectEnabled() && !this.isProactiveConnectEnabled();
  }

  isPlusAndConnectReactiveOnlyProvider(): boolean {
    return !this.isBnplEnabled() && this.isPlusEnabled() && this.isConnectEnabled() && !this.isProactiveConnectEnabled();
  }

  isPlusAndConnectProactiveOnlyProvider(): boolean {
    return !this.isBnplEnabled() && this.isPlusEnabled() && this.isConnectEnabled() && this.isProactiveConnectEnabled();
  }

  isOtpEnabled(): boolean {
    return this.getConnectServices()?.enableOtpConnect;
  }

  isComplianceEnabled(): boolean {
    return this.getConnectServices()?.enableComplianceConnect;
  }

  isKycEnabled(): boolean {
    return this.getConnectServices()?.enableKYCConnect;
  }

  isRpEntryConnectEnabled(): boolean {
    return this.getConnectServices()?.enableRpEntryConnect;
  }

  isPaymentPlanAssistantEnabled(): boolean {
    return this.getSettings()?.features.paymentPlanAssistant;
  }

  isSmsJourneyConnectEnabled(): boolean {
    return this.getConnectServices().enableSmsJourneyConnect;
  }

  isPdfPrintConnectEnabled(): boolean {
    return this.getConnectServices().enablePdfPrintConnect;
  }

  isTreatmentProposalEnabled(): boolean {
    return this.getSettings()?.features.treatmentProposal;
  }

  isSmileTrackerEnabled(): boolean {
    return this.getSettings()?.features.enableSmileTracker;
  }

  getMaxTemplate(): string {
    return this.getSettings()?.features.maxTemplate;
  }

  getFpdPreference(): TextValuePair | null {
    const value = this.getGeneralSettings().firstPaymentDatePreference;

    return Array.isArray(value) ? null : value;
  }

  getDefaultDiscSetting(): TextValuePair | null {
    const value = this.getSettings()?.features.treatmentProposalDiscountSettings;

    return Array.isArray(value) ? null : value;
  }

  isBasiqEnabled(): boolean {
    return this.getConnectServices().enableBasiq;
  }

  isPayInFullEnabled(): boolean {
    return this.getSettings()?.features.enablePayInFull;
  }

  getDefaultDepositPayee(): TextValuePair {
    return this.getGeneralSettings().depositOwner;
  }

  isPromptPayEnabled(): boolean {
    return this.getSettings()?.features.promptPay;
  }

  getPromptPaySmsLimit(): string {
    return this.getSettings()?.features.promptPaySmsLimit;
  }

  isPayDepositToEnabled(): boolean {
    return this.getSettings().features.enableDepositConfig;
  }

  getProviderType(): ProviderType {
    return JSON.parse(sessionStorage.getItem('user') as string)?.clinic_type;
  }

  getProviderConsent(): string {
    return this.getGeneralSettings().rpConsent || '';
  }

  isViewDetailsConnectEnabled(): boolean {
    return this.getConnectServices()?.enableViewDetailsConnect;
  }
}
